<main role="main">

  <section>
    <article>
      <div class="section background-white padding">
        <div class="line">
          <!-- Catalogus -->

          <div class="line">
            <h2 class="text-size-30 text-s-size-12 text-green">
              <em>{{translate('Favorieten')}}!</em>
            </h2>
            <hr class="break break-small background-primary">
            <div class="box margin-bottom" style="min-height:300px;">
              <div class="margin">
                <div class="s-12">
                  <div class="line">
                    <div class="line">
                      <app-webshopfavorietenitem></app-webshopfavorietenitem>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="line">
        <div class="margin">
          <hr class="break text-dark">
          <div class="s-3 m-3 l-3 text-left text-green">
            <strong>
              <img src="../../../../assets/images/Allerlei/levering winkelmand.png" alt="gratis levering">
            </strong>
          </div>
          <div class="s-3 m-3 l-3">
            <h6>
              <strong>
                <em>{{translate('Gratis levering voor bestellingen vanaf €150,00 excl. BTW')}}</em>
              </strong>
            </h6>
            <a class="text-more-info text-green-hover" [routerLink]="getrouterlink('/webshop/tandarts')">{{translate('Nog iets vergeten! Naar de webshop')}}</a>
          </div>
          <div class="s-6 m-6 l-6 text-right">
          </div>
        </div>
      </div>
      <br/>
      <br/>
      <!-- Kleurbalk -->
      <div class="background-dark padding text-center"> </div>
    </article>
  </section>
</main>
