import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Language } from '../models/enum';
import { Translation } from '../models/translation';

@Injectable()
export class LanguageService {

    constructor() { this.InitTranslation(); }

    public Taal: Language = Language.NL;
    public TaalEnumString = "NL";
    public TaalString: string = "FR";

    public switchTaal() {
        if (this.Taal == Language.NL) {
            this.Taal = Language.FR;
            this.TaalString = "NL";
            this.TaalEnumString = "FR";
        }
        else {
            this.Taal = Language.NL;
            this.TaalString = "FR";
            this.TaalEnumString = "NL";
        }
    }
    public translate(val: string): string {
        if (this.Taal == Language.FR) {
            var valx;
            this.Translations.forEach(element => { if (element.NL == val) valx = element.FR });
            if (valx == undefined)
                valx = "NO TRANSLATION: " + val;
            return valx;
        }
        else
            return val;

    }
    private Translations: Translation[]=[];
    private InitTranslation() {
        if (this.Translations.length == 0) {
            this.Translations = [
                new Translation("Producten bestellen", "Commander des produits"),
                new Translation("Tandartsen", "Dentistes"),
                new Translation("Tandarts", "Dentiste"),
                new Translation("Tandtechnische labo's", "Laboratoires dentaire"),
                new Translation("Tandtechnisch labo", "Laboratoire dentaire"),
                new Translation("Over Providental", "Concernant providental"),
                new Translation("Betalingsvoorwaarden", "Conditions de vente"),
                new Translation("Leveringsvoorwaarden", "Conditions de livraison"),
                new Translation("Hoe bestellen", "Comment passer commande"),
                new Translation("Kortingen", "Réduction/remises"),
                new Translation("Links fabrikanten", "Fabricants"),
                new Translation("e-catalogus in pdf", "E-catalogue en pdf"),
                new Translation("Inloggen", "Se connecter"),
                new Translation("Login", "Login"),
                new Translation("Paswoord", "Mot de passe"),
                new Translation("Onthoud me", "Souvenez moi"),
                new Translation("Registreer hier", "S’enregistrer"),
                new Translation("Paswoord vergeten", "Mot de passe oublié?"),
                new Translation("Artikel zoeken", "Rechercher un article"),
                new Translation("Winkelwagen", "Ma commande"),
                new Translation("Favorieten", "Favoris"),
                new Translation("Inhoud winkelwagen", "Contenu de votre commande"),
                new Translation("Artikel", "Article"),
                new Translation("Omschrijving", "Déscription"),
                new Translation("Merk", "Marque"),
                new Translation("Artikelnummer", "Référence"),
                new Translation("Inhoud", "Contenu"),
                new Translation("Aantal", "Quantité"),
                new Translation("Prijs", "Prix"),
                new Translation("Totaal", "Total"),
                new Translation("Del", "Del"),
                new Translation("Barcode inscannen of artikelnr ingeven", "Scanner le code à barres ou entrez le numéro de l’article ici"),
                new Translation("Toevoegen", "Ajouter"),
                new Translation("Gratis levering voor bestellingen vanaf €150,00 excl. BTW", "Livraison gratuite pour les commandes à partir de 150,00 euros sans TVA"),
                new Translation("Nog iets vergeten! Naar de webshop", "Oubliez quelque chose? A la boutique en ligne"),
                new Translation("Bestellen", "Commander"),
                new Translation("Subtotaal", "Sous total"),
                new Translation("Verzendkosten", "Frais de port"),
                new Translation("BTW", "TVA"),
                new Translation("Gratis vanaf € 150,00", "Gratuit à partir de 150,00"),
                new Translation("Bestel Overzicht", "Récapitulatif de la commande"),
                new Translation("Terug naar de winkelmand", "Retour au panier"),
                new Translation("Bestelling Afronden", "Terminer la commande"),
                new Translation("Leveren", "Livrer"),
                new Translation("Afhalen", "Ramasser"),
                new Translation("Leverings/Afhaal gegevens", "Livrer / Ramasser"),
                new Translation("Aflever-adres", "Adresse de livraison"),
                new Translation("Orderbevestiging Providental", "Confirmation de commande Providental"),
                new Translation("Hierbij bevestigen wij de ontvangst van uw bestelling", "Par la présente, nous confirmons la réception de votre commande"),
                new Translation("Hartelijk dank voor uw vertrouwen", "Merci pour votre confiance"),
                new Translation("Leveringswijze", "Méthode de livraison"),
                new Translation("Leveringsadres", "Adresse de livraison"),
                new Translation("Klantnummer", "Numéro de client"),
                new Translation("Naam", "Nom"),
                new Translation("Adres", "Adresse"),
                new Translation("Home", "Home"),
                new Translation("Contact", "Contact"),
                new Translation("Categorieën", "Catégories"),
                new Translation("Klik hier", "Cliquer ici"),
                new Translation("Titel A - Z", "Titre A - Z"),
                new Translation("Titel Z - A", "Titre Z - A"),
                new Translation("Prijs laag - hoog", "Prix ​​bas - haut"),
                new Translation("Prijs hoog - laag", "Prix ​​haut - bas"),
                new Translation("Merk A - Z", "Marque A - Z"),
                new Translation("Merk Z - A", "Marque Z - A"),
                new Translation("Art.nr A - Z", "Art.no A - Z"),
                new Translation("Art.nr Z - A", "Art.no Z - A"),
                new Translation("Promo", "Promo"),
                new Translation("Filter...", "Filtrer..."),
                new Translation("Sortering", "Trier"),
                new Translation("Productnr", "Produit no."),
                new Translation("Art.nr", "Art.no"),
                new Translation("Prijsinformatie", "Informations sur les prix"),
                new Translation("P.O.A.", "P.S.D."),
                new Translation("Stukprijs", "Prix ​​unitaire"),
                new Translation("Stukprijs per", "Prix ​​unitaire par"),
                new Translation("Loading prices", "Chargement des prix"),
                new Translation("Art.Nr", "Art.No"),
                new Translation("Prod.Nr", "Prod.No"),
                new Translation("Bestel", "Commande"),
                new Translation("Favoriet", "Préféré"),
                new Translation("Log Out", "Se déconnecter"),
                new Translation("Toegevoegd", "Ajouté"),
                new Translation("Verwijder", "Supprimer"),
                new Translation("Delete", "Supprimer"),
                new Translation("EH. Prijs", "Prix unitaire"),
                new Translation("Onthou me", "Souvenez moi"),
                new Translation("Registreer hier!", "S'inscrire ici!"),
                new Translation("Paswoord vergeten!", " Oublié votre mot de passe!"),
                new Translation("Login / Registreren", "Se connecter / S'enregistrer"),
                new Translation("Nieuwe klant", "Nouveaux clients"),
                new Translation("Vul hier uw voornaam in.", "Entrez votre prénom ici."),
                new Translation("Vul hier uw naam.", "Entrez votre nom ici."),
                new Translation("Plaats hier uw e-mail.", "Entrez votre email ici."),
                new Translation("Vul hier uw land in.", "Entrez votre pays ici."),
                new Translation("Vul hier uw straat in.", "Entrez votre rue ici."),
                new Translation("Vul hier uw huisnummer in.", "Entrez votre numéro de maison ici"),
                new Translation("Vul hier uw postcode in.", "Entrez votre code postal ici."),
                new Translation("Vul hier uw woonplaats in.", "Entrez votre résidence ici,"),
                new Translation("Vul hier uw Telefoonnummer in.", "Entrez votre numéro de téléphone ici."),
                new Translation("Vul hier uw GSM in.", "Entrez votre numéro de GSM ici."),
                new Translation("Voornaam", "Prénom"),
                new Translation("Naam", "Nom de famille"),
                new Translation("Uw e-mail", "Adresse mail"),
                new Translation("België", "Belgique"),
                new Translation("Straat", "Rue"),
                new Translation("Huisnummer", "No"),
                new Translation("Postcode", "Code postal"),
                new Translation("Woonplaats", "Ville"),
                new Translation("Telefoonnummer", "Téléphone"),
                new Translation("GSM", "GSM"),
                new Translation("Verzenden", "Expédier"),
                new Translation("Uw bericht werd succesvol verzonden.", "Votre message a été envoyé avec succès."),
                new Translation("Reeds klant", "Clients actuels"),
                new Translation("Vul hier uw login in.", "Entrez votre identifiant ici."),
                new Translation("Vul hier uw paswoord in.", "Entrez votre mot de passe ici."),
                new Translation("Paswoord vergeten:", "Mot de passe oublié:"),
                new Translation("paswoord", "mot de passe"),
                new Translation("e-mailadres", ""),
                new Translation("vergeten? Vul hieronder je", "? Entrez votre e-mail ci-dessous."),
                new Translation("in.", ""),
                new Translation("Bestellingen", "Ordres"),
                new Translation("Geleverd", "Livré"),
                new Translation("Nummer", "No."),
                new Translation("Datum", "Date"),
                new Translation("Openingsuren", "Heures d'ouverture"),
                new Translation("maandag tot en met vrijdag: 8.30u tot 17.00", "Lundi au Vendredi entre 8h30 et 17h00"),
                new Translation("Alle rechten voorbehouden", "Tous droits réservés"),
                new Translation("Zie hier uw vorige bestellingen", "Voir vos commandes ici"),
                new Translation("Ben je je", "Avez-vous oublié votre"),
                new Translation("Voorwaarden", "Conditions"),
                new Translation("Plaats hier uw e-mail.", "Placez votre email ici."),
                new Translation("Uw aanvraag is verzonden", "Votre demande a été envoyé"),
                new Translation("Bekijk meer", "Voir plus")];
        }
    }
}
